const formatter = {}

formatter.stateFormatter = (s) => {
    switch (s.state) {
        case 5:
            return "已忽略"
        case 4:
            return "已叫号"
        case 3:
            return "已沟通"
        case 2:
            return "已完成"
        case 1:
            return "已到院"
        case 0:
            return "已预约"
        case -1:
            return "用户取消"
        default:
            return s.state
    }
}

export default formatter
